const envConfig = require("./env-config.js");
const baseUrl = require("./config/baseUrl.json");

let setting = {
	title: 'ZH',
	server: 'Local',

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether show the settings right-panel
	//----------------------------------------
	showSettings: true,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether need tagsView
	//----------------------------------------  
	tagsView: true,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether fix the header
	//----------------------------------------

	fixedHeader: false,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether show the logo in sidebar
	//----------------------------------------

	sidebarLogo: false,

	//----------------------------------------
	// @type {string | array} 'production' | ['production', 'development']
	// @description Need show err logs component.
	// The default is only used in the production env
	// If you want to also use it in dev, you can pass ['production', 'development']
	//----------------------------------------

	errorLog: 'production',

	//----------------------------------------
	// @type {string}
	// @description Url of token validate, pls dont change this config
	//----------------------------------------

	token_url: 'https://us-central1-zombie-survival-95140593.cloudfunctions.net',

	//----------------------------------------
	//  @type {string}
	//  @description Url of base services
	//----------------------------------------

	firebase_url: 'https://us-central1-ds-test-4135a.cloudfunctions.net',

	//----------------------------------------
	// @type {string}
	// @description Url of link to login
	//----------------------------------------

	service_login: 'https://madops.api-mps.mto.zing.vn/login',
	service_logout: 'https://madops.api-mps.mto.zing.vn/logout',

	//----------------------------------------
	//  @type {string}
	//  @description Services:
	//----------------------------------------

	// service UserProfile
	urlServiceUserProfile: `http://127.0.0.1:${envConfig.userProfile_port}/user`,
	urlServiceUserProfile_ios: `http://127.0.0.1:${envConfig.userProfile_port}/user`,

	// service Clan
	urlServiceClan: `http://127.0.0.1:${envConfig.clan_port}/clan`,
	urlServiceClan_ios: `http://127.0.0.1:${envConfig.clan_port}/clan`,

	// service Message
	urlServiceMessage: `http://127.0.0.1:${envConfig.message_port}/message`,
	urlServiceMessage_ios: `http://127.0.0.1:${envConfig.message_port}/message`,

	// service Admin
	urlServiceAdmin: `http://127.0.0.1:${envConfig.admin_port}/admin`,
	urlServiceAdmin_ios: `http://127.0.0.1:${envConfig.admin_port}/admin`,

	// service ClanLDB
	urlServiceClanLDB: `http://127.0.0.1:${envConfig.clanLdb_port}/clanldb`,
	urlServiceClanLDB_ios: `http://127.0.0.1:${envConfig.clanLdb_port}/clanldb`,

	// service LeaderboardBase
	urlServiceLeaderboardBase: `http://127.0.0.1:${envConfig.leaderboardBase_port}/lb-base`,
	urlServiceLeaderboardBase_ios: `http://127.0.0.1:${envConfig.leaderboardBase_port}/lb-base`,

	// service LeaderboardBase
	urlServiceBase: `http://127.0.0.1:${envConfig.serviceBase_port}/api`,
	urlServiceBase_ios: `http://127.0.0.1:${envConfig.serviceBase_port}/api`,

	//----------------------------------------
	// @type {string}
	// @description Url of auth link after to login
	//----------------------------------------

	auth_redirect: `http://127.0.0.1:${envConfig.admintool_port}/#/auth-redirect`,

	logout_redirect: `http://127.0.0.1:${envConfig.admintool_port}/#/login`,
	logout_redirect_ios: `http://127.0.0.1:${envConfig.admintool_port}/#/login_ios`,

	// custom leaderboard url
	leader_board: `http://127.0.0.1:${envConfig.leaderboard_port}/zslb`,
	leader_board_ios: `http://127.0.0.1:${envConfig.leaderboard_port}/zslb`,
	upload_file_CDN: `http://127.0.0.1:${envConfig.upload_port}/up`,
	upload_file_CDN_ios: `http://127.0.0.1:${envConfig.upload_port}/up`,
	leader_board_helper: `http://127.0.0.1:${envConfig.leaderboard_helper_port}/lbhelper`,
	leader_board_helper_ios: `http://127.0.0.1:${envConfig.leaderboard_helper_port}/lbhelper`,
	leader_board_helper_blacklist: `http://127.0.0.1:${envConfig.leaderboard_port}/zslb`,
	leader_board_helper_blacklist_ios: `http://127.0.0.1:${envConfig.leaderboard_port}/zslb`,
	//ToDo: download from local
	download_file_CDN: `http://127.0.0.1:${envConfig.upload_port}/up/resource`,
	download_file_CDN_ios: `http://127.0.0.1:${envConfig.upload_port}/up/resource`,

	gitVersion: envConfig.gitVersion,
}

let baseUrlIosString = "";
let baseUrlAndroidString = "";
let adminToolUrlString = "";

console.log(`envConfig.env = ${envConfig.env}`);

if (envConfig.env === "dev") {
	baseUrlIosString = baseUrl.dev_ios;
	baseUrlAndroidString = baseUrl.dev_android;
	adminToolUrlString = baseUrl.dev_admintool;
	setting.server = "Dev";
} else if (envConfig.env === "beta") {
	baseUrlIosString = baseUrl.beta_ios;
	baseUrlAndroidString = baseUrl.beta_android;
	adminToolUrlString = baseUrl.beta_admintool;
	setting.server = "Beta";
    setting.firebase_url = "https://us-central1-zombie-hunter-live-like.cloudfunctions.net";
} else if (envConfig.env === "live") {
	baseUrlIosString = baseUrl.live_ios;
	baseUrlAndroidString = baseUrl.live_android;
	adminToolUrlString = baseUrl.live_admintool;
	setting.server = "LIVE";
    setting.firebase_url = "https://us-central1-zombie-survival-95140593.cloudfunctions.net";
}

if (baseUrlAndroidString !== "") {
	setting.urlServiceUserProfile = `${baseUrlAndroidString}/user`;
	setting.urlServiceUserProfile_ios = `${baseUrlIosString}/user`;

	setting.urlServiceClan = `${baseUrlAndroidString}/clan`;
	setting.urlServiceClan_ios = `${baseUrlIosString}/clan`;

	setting.urlServiceMessage = `${baseUrlAndroidString}/message`;
	setting.urlServiceMessage_ios = `${baseUrlIosString}/message`;

	setting.urlServiceAdmin = `${baseUrlAndroidString}/admin`;
	setting.urlServiceAdmin_ios = `${baseUrlIosString}/admin`;

	setting.urlServiceClanLDB = `${baseUrlAndroidString}/clanldb`;
	setting.urlServiceClanLDB_ios = `${baseUrlIosString}/clanldb`;

	setting.urlServiceLeaderboardBase = `${baseUrlAndroidString}/lb-base`;
	setting.urlServiceLeaderboardBase_ios = `${baseUrlIosString}/lb-base`;

	setting.urlServiceBase = `${baseUrlAndroidString}/api`;
	setting.urlServiceBase_ios = `${baseUrlIosString}/api`;

	setting.auth_redirect = `${adminToolUrlString}/#/auth-redirect`;

	setting.logout_redirect = `${adminToolUrlString}/#/login`;
	setting.logout_redirect_ios = `${adminToolUrlString}/#/login_ios`;

	setting.leader_board = `${baseUrlAndroidString}/zslb`;
	setting.leader_board_ios = `${baseUrlIosString}/zslb`;

	setting.upload_file_CDN = `${baseUrlAndroidString}/up`;
	setting.upload_file_CDN_ios = `${baseUrlIosString}/up`;

	setting.leader_board_helper = `${baseUrlAndroidString}/lbhelper`;
	setting.leader_board_helper_ios = `${baseUrlIosString}/lbhelper`;

	setting.leader_board_helper_blacklist = `${baseUrlAndroidString}/zslb`;
	setting.leader_board_helper_blacklist_ios = `${baseUrlIosString}/zslb`;

	if (envConfig.env === "live") {
		setting.download_file_CDN = `https://deadtarget-cdn.mto.zing.vn/resource/zs/cdn/android`;
		setting.download_file_CDN_ios = `https://deadtarget-cdn.mto.zing.vn/resource/zs/cdn/ios`;
	} else {
		setting.download_file_CDN = `${baseUrlAndroidString}/up/resource`;
		setting.download_file_CDN_ios = `${baseUrlIosString}/up/resource`;
	}	
}

// console.log(`setting = ${JSON.stringify(setting)}`);

module.exports = setting
