const envConfigJson = require("./config/.env.json");

let envConfig = {
    env: envConfigJson.flavor,
    os: envConfigJson.os,

    cdn_url: envConfigJson.cdn_url,

    mongodb_url_connect: envConfigJson.mongodb_url_connect,

    redis_url: envConfigJson.redis_url,
    redis_port: envConfigJson.redis_port,

    admin_port: envConfigJson.admin_port,
    admin_FullUrl: `${envConfigJson.admin_url}:${envConfigJson.admin_port}`,

    clan_port: envConfigJson.clan_port,
    clan_FullUrl: `${envConfigJson.clan_url}:${envConfigJson.clan_port}`,

    clanLdb_port: envConfigJson.clanLdb_port,
    clanLdb_FullUrl: `${envConfigJson.clanLdb_url}:${envConfigJson.clanLdb_port}`,

    leaderboard_port: envConfigJson.leaderboard_port,
    leaderboard_FullUrl: `${envConfigJson.leaderboard_url}:${envConfigJson.leaderboard_port}`,

    leaderboard_helper_port: envConfigJson.leaderboard_helper_port,
    leaderboard_helper_FullUrl: `${envConfigJson.leaderboard_helper_url}:${envConfigJson.leaderboard_helper_port}`,

    message_port: envConfigJson.message_port,
    message_FullUrl: `${envConfigJson.message_url}:${envConfigJson.message_port}`,

    raidboss_port: envConfigJson.raidboss_port,
    raidboss_FullUrl: `${envConfigJson.raidboss_url}:${envConfigJson.raidboss_port}`,

    upload_port: envConfigJson.upload_port,
    upload_FullUrl: `${envConfigJson.upload_url}:${envConfigJson.upload_port}`,

    userProfile_port: envConfigJson.userProfile_port,
    userProfile_FullUrl: `${envConfigJson.userProfile_url}:${envConfigJson.userProfile_port}`,

    leaderboardBase_port: envConfigJson.leaderboardBase_port,
    leaderboardBase_FullUrl: `${envConfigJson.leaderboardBase_url}:${envConfigJson.leaderboardBase_port}`,

    serviceBase_port: envConfigJson.serviceBase_port,
    serviceBase_FullUrl: `${envConfigJson.serviceBase_url}:${envConfigJson.serviceBase_port}`,

    admintool_port: envConfigJson.admintool_port,
    gitVersion: envConfigJson.gitversion
}

module.exports = envConfig;
