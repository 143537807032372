import router from './router'
import store from './store'
import { getToken, removeToken, getAvatarURL, setOSToken, getOSToken } from "@/utils/auth"
import getPageTitle from '@/utils/get-page-title'
import { constantRoutes } from '@/router'

let tokenTime = 0

router.beforeEach(async (to, from, next) => {

	document.title = getPageTitle(to.meta.title)

	console.log("to : " + to.path);

	const token = getToken();

	if (token !== undefined) {
		if (to.path === '/login') {
			next({ path: '/' })
		}
		else {
			let d = Date.now();
			let deltaTime = d - tokenTime;

			if (deltaTime >= 30 * 60 * 1000) {
				console.log("os: " + getOSToken());
				try {
					let info = await store.dispatch('permission/verifyToken', token)
					store.dispatch("user/setInfo", {
						name: info.name,
						avatar: getAvatarURL(info.name),
						email: info.email,
						os: getOSToken(),
						roles: ["admin"]
					});
				} catch (ex) {
					console.log(ex);
					removeToken();
					next({ path: '/' });
					return;
				}
				tokenTime = d;

				let userInList = [
					"sontt@vng.com.vn",
					"uandm@vng.com.vn",
					"anhntm2@vng.com.vn",
					"xuannt5@vng.com.vn",
					"quyenlb@vng.com.vn",
					"tintt2@vng.com.vn",
					"trieunk@vng.com.vn",
					"tuenng@vng.com.vn",
					"thongpm2@vng.com.vn",
					"hunght3@vng.com.vn",
					"khoancd@vng.com.vn",
					"quannlm@vng.com.vn",
					"khoatla@vng.com.vn",
					"anhnx@vng.com.vn",
					"phuongttt@vng.com.vn",
					"doanlm@vng.com.vn",
					"longhlk@vng.com.vn",
					"thanhct@vng.com.vn",
					"nhipty3@vng.com.vn",
					"haoln@vng.com.vn"
				];
				if (userInList.indexOf(store.getters.email) < 0) {
					removeToken();
					next(`/logout`);
					return;
				}

			}
			else {
				console.log("Skip test token: " + deltaTime / 1000);
			}

			const initRoute = store.getters.init_route;
			if (initRoute === true) {
				next()
			} else {
				const roles = ['admin'];
				const accessRoutes = await store.dispatch('permission/generateRoutes', roles);

				// dynamically add accessible routes
				//router.addRoutes(accessRoutes)
				// hack method to ensure that addRoutes is complete
				// set the replace: true, so the navigation will not leave a history record
				// reload hasRoles again

				accessRoutes.forEach(routerElement => {
					router.addRoute(routerElement);
				});

				next({ ...to, replace: true })
			}
		}
	}
	else {

		const whiteList = ['/login', '/logout', '/auth-redirect', '/login_android', '/login_ios']

		if (to.path === "/login_ios") {
			setOSToken("iOS");
		}

		if (to.path === "/login" || to.path === "/login_android") {
			setOSToken("Android")
		}

		if (whiteList.indexOf(to.path) !== -1) {
			// in the free login whitelist, go directly
			next();
		} else {
			let tempOS = getOSToken();
			if (tempOS === "iOS") {
				next('/login_ios');
			}
			else {
				next(`/login_android`);
			}
		}

	}
})

router.afterEach(() => {

})
